<template>
    <div class="row justify-content-end align-items-center flex-wrap">
        <div class="d-flex">
            <div style="min-width: 120px; align-self: center;">
                Rows per page:
            </div>
            <b-form-select
                :value="pagination.perPage"
                :options="[12, 24, 48, 96]"
                @change="onPerPageChange"
            />
        </div>
        <div class="ml-3 mr-2">
            <template v-if="pagination.total">
                {{ recordsFrom }}-{{ recordsTo }} of {{ pagination.total }}
            </template>
            <template v-else>
                0 of 0
            </template>
        </div>
        <ul class="pagination pagination-rounded align-items-center pt-2 mr-3">
            <li class="page-item" :class="{ disabled: pagination.page === 1 }">
                <span
                    class="page-link"
                    aria-label="Previous"
                    @click.prevent="setPage(pagination.page - 1)"
                >
                    <i class="fas fa-angle-left" />
                </span>
            </li>
            <li
                class="page-item"
                :class="{ disabled: pagination.page === lastPage }"
            >
                <span
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="setPage(pagination.page + 1)"
                >
                    <i class="fas fa-angle-right" />
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            required: true,
            default: () => ({
                page: 1,
                perPage: 12,
                total: 0
            })
        }
    },

    computed: {
        lastPage() {
            return Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        recordsFrom() {
            return (
                this.pagination.page * this.pagination.perPage -
                this.pagination.perPage +
                1
            );
        },
        recordsTo() {
            const to = this.pagination.page * this.pagination.perPage;

            return to > this.pagination.total ? this.pagination.total : to;
        }
    },

    methods: {
        setPage(page) {
            this.$emit('paginationChange', {
                ...this.pagination,
                page: page
            });
        },

        onPerPageChange(perPage) {
            this.$emit('paginationChange', {
                ...this.pagination,
                page: 1,
                perPage
            });
        }
    }
};
</script>
