<template>
    <div class="col-12 form-group">
        <label :for="customRef">Files</label>
        <vue-dropzone
            :id="customRef"
            :ref="customRef"
            class="h-100 d-flex justify-content-center align-items-center flex-wrap"
            :options="multiDropzoneOptions"
            @vdropzone-success-multiple="onSuccess"
        />
        <div class="d-flex justify-content-center mt-2">
            <button
                class="btn btn-success waves-effect waves-light"
                data-animation="fadein"
                data-overlaycolor="#38414a"
                :disabled="isUploading"
                @click="uploadImages"
            >
                <span v-if="isUploading" class="media-spinner" />
                <span v-else>
                    Upload files
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import config from '@/config';

export default {
    components: {
        vueDropzone: vue2Dropzone
    },

    props: {
        refProps: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            dropzoneRelativePath: '/api/media',
            isUploading: false
        };
    },

    computed: {
        multiDropzoneOptions() {
            const options = {
                url: config.apiUrl + this.dropzoneRelativePath,
                thumbnailWidth: 150,
                maxFilesize: 20,
                parallelUploads: 100,
                uploadMultiple: true,
                acceptedFiles: this.type ? `${this.type}/*` : 'image/*,image/heic,video/*',
                dictDefaultMessage: "Upload New Files <i class='fe-upload'/>",
                addRemoveLinks: true,
                duplicateCheck: true,
                withCredentials: true,
                autoProcessQueue: false
            };

            return options;
        },

        customRef() {
            if (this.refProps) {
                return this.refProps;
            }

            return (
                Math.random()
                    .toString(36)
                    .substring(2, 30)
                    .match(/[a-zA-Z]+/g)
                    .join('') + 'ImageDropzone'
            );
        }
    },

    methods: {
        uploadImages() {
            this.isUploading = true;

            this.$refs[this.customRef].processQueue();
        },

        onSuccess(files, response) {
            this.isUploading = false;

            this.$emit('uploaded', response);

            this.$refs[this.customRef].removeAllFiles();
        }
    }
};
</script>
