<template>
    <div>
        <search-params
            :params="searchParams"
            :disable-type-select="!!type"
            @searchChange="onSearchChange"
        />

        <div class="row text-center text-lg-left">
            <div
                v-for="mediaItem in mediaItems"
                :key="
                    isDeletable
                        ? `${mediaItem.id}_deletable`
                        : `${mediaItem.id}`
                "
                class="col-xl-2 col-md-3 col-sm-4 col-6 mb-3 image-tile"
                :class="{ small: !!type }"
                @click.prevent="selectMedia(mediaItem)"
                @dblclick="forceSelect"
            >
                <div
                    class="d-flex mb-4 h-100 preview-image-container"
                    :class="{
                        selected: getSelectedIndex(mediaItem) !== -1
                    }"
                >
                    <div
                        v-if="showCheckboxes"
                        class="multiple-checkbox"
                        @click.stop="toggleMedia(mediaItem)"
                    />
                    <div class="media-item-overlay">
                        <div
                            v-if="isDeletable"
                            class="media-preview-item-remove"
                            @click.stop
                        >
                            <button
                                type="button"
                                class="btn btn-danger rounded"
                                @click="deleteMedia(mediaItem.id)"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </div>
                        <div class="media-item-title">
                            {{ mediaItem.name }}
                        </div>
                    </div>
                    <div v-media-url="mediaItem" />
                </div>
            </div>
        </div>
        <pagination
            :pagination="pagination"
            label="Files per page:"
            @paginationChange="onPaginationChange"
        />
        <div
            v-if="showDetails && media.id"
            class="row media-preview-details justify-content-center"
        >
            <hr class="col-12" />
            <media-details
                :key="selectedMedia[0].id || 'none'"
                :media="media"
                class="col-12 col-lg-8"
                @updated="onMediaUpdate"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Pagination from './Pagination';
import MediaDetails from './Details';
import SearchParams from './SearchParams';

export default {
    components: {
        Pagination,
        MediaDetails,
        SearchParams
    },

    props: {
        value: {
            type: [Array, Object, String],
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        isDeletable: {
            type: Boolean,
            required: false,
            default: false
        },
        multipleMode: {
            type: Boolean,
            required: false,
            default: false
        },
        showDetails: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            mediaItems: [],
            selectedMedia: [],
            media: {},
            pagination: {
                page: 1,
                perPage: 12,
                total: 0
            },
            searchParams: {
                type: null,
                date: null
            }
        };
    },

    computed: {
        showCheckboxes() {
            if (!this.multipleMode) {
                return false;
            }

            if (this.selectedMedia.length) {
                return true;
            }

            return false;
        }
    },

    watch: {
        selectedMedia() {
            this.$emit('onSelectMedia', this.selectedMedia);
        }
    },

    created() {
        if (this.type) {
            this.searchParams = { type: this.type };
        }

        this.fetchMedia();

        if (!this.value) {
            return;
        }

        if (Array.isArray(this.value)) {
            this.selectedMedia = [...this.value];

            return;
        }

        if (this.value.id) {
            this.selectedMedia = [{ ...this.value }];

            return;
        }

        if (this.value) {
            this.selectedMedia = [{ id: this.value }];

            
        }
    },

    methods: {
        ...mapActions({
            getMedia: 'media/index',
            deleteMedia: 'media/delete'
        }),

        async fetchMedia() {
            try {
                const { rows, count } = await this.getMedia({
                    ...this.pagination,
                    searchParams: this.searchParams
                });

                this.mediaItems = rows;
                this.pagination.total = count;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        getSelectedIndex(mediaItem) {
            return this.selectedMedia.findIndex(
                item => item.id === mediaItem.id
            );
        },

        toggleMedia(mediaItem) {
            const index = this.getSelectedIndex(mediaItem);

            if (index === -1) {
                this.selectedMedia.push(mediaItem);
            } else {
                this.selectedMedia.splice(index, 1);

                if (this.selectedMedia.length === 1) {
                    this.selectMedia(this.selectedMedia[0]);
                }
            }
        },

        selectMedia(media) {
            this.selectedMedia = [{ ...media }];
            this.media = { ...media };
        },

        forceSelect() {
            if (!this.multipleMode) {
                this.$emit('forceSelect');
            }
        },

        deleteMedia(mediaId) {
            this.$emit('onDeleteMedia', mediaId);
        },

        onPaginationChange(pagination) {
            this.pagination = { ...pagination };

            this.fetchMedia();
        },

        onSearchChange(searchParams) {
            this.searchParams = { ...searchParams };
            this.fetchMedia();
        },

        onMediaUpdate(media) {
            this.selectedMedia = [{ ...media }];
        },
    }
};
</script>
