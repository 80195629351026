<template>
    <div>
        <div class="form-group">
            <label for="name">Name</label>
            <div class="input-group">
                <input
                    id="name"
                    v-model="name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.name.$error }"
                    placeholder="Name"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="alt">Alt Text</label>
            <div class="input-group">
                <input
                    id="alt"
                    v-model="alt"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.alt.$error }"
                    placeholder="Alt text"
                />
            </div>
        </div>
        <div class="form-group">
            <b-form-checkbox
                v-model="disable_compression"
                switch
            >
                Disable compression
            </b-form-checkbox>
        </div>
        <div>
            <div
                class="image-preview"
                :class="{
                    'details-only': !preview
                }"
            >
                <div v-if="preview" v-media-url.controls="media" />
                <div
                    class="media-details"
                    :class="{
                        'details-only': !preview
                    }"
                >
                    <div class="details-item">
                        <b>Size:</b> {{ media.size }}B
                    </div>
                    <template v-if="media.type === 'image'">
                        <div class="details-item">
                            <b>Width:</b> {{ media.meta.width }}px
                        </div>
                        <div class="details-item">
                            <b>Height:</b> {{ media.meta.height }}px
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div
            class="d-flex"
            :class="preview ? 'justify-content-between' : 'justify-content-end'"
        >
            <button
                v-if="preview"
                type="button"
                class="btn btn-danger waves-effect waves-light mr-2"
                @click="cancel"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="updateHandler"
            >
                Update
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    validations: {
        name: {
            required
        },
        alt: {
            required
        }
    },

    props: {
        media: {
            type: Object,
            required: true,
            default: () => ({})
        },
        preview: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            name: '',
            alt: '',
            disable_compression: false
        };
    },

    watch: {
        media: {
            handler() {
                this.name = this.media.name;
                this.alt = this.media.alt;
                this.disable_compression = this.media.disable_compression;
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions({
            updateMedia: 'media/update'
        }),

        async updateHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const media = await this.updateMedia({
                    id: this.media.id,
                    name: this.name,
                    alt: this.alt,
                    disable_compression: this.disable_compression
                });

                this.$toaster('File has been updated.');

                this.$emit('updated', media);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        cancel() {
            this.$emit('cancel');
        }
    }
};
</script>
