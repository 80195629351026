<template>
    <div class="row mb-2 pt-3">
        <div class="col-12 col-md-4 col-lg-3 d-flex p-0">
            <div class="col-12 form-group">
                <label for="search-type">Search by type:</label>
                <v-select
                    v-model="searchParams.type"
                    :options="mediaOptions"
                    label="text"
                    :clearable="false"
                    :disabled="disableTypeSelect"
                    :reduce="option => option.value"
                />
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 d-flex p-0">
            <div class="col-12 form-group">
                <label for="search-date">Search by date:</label>
                <v-select
                    v-model="searchParams.date"
                    :options="months"
                    label="text"
                    :clearable="false"
                    :reduce="option => option.value"
                />
            </div>
        </div>

        <div class="col-12 col-md-4 col-lg-6 d-flex p-0 pr-3 pl-3">
            <div class="pr-1" style="width: 100%; margin-bottom: 1rem">
                <label for="search">Search for:</label>
                <input
                    v-model="searchParams.name"
                    type="text"
                    class="form-control"
                    dir="auto"
                    placeholder="Type something"
                />
            </div>
            <div class="form-group d-flex align-items-end">
                <button
                    class="btn btn-danger waves-effect waves-light"
                    @click="emit"
                >
                    <i class="fas fa-search" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        params: {
            type: Object,
            required: true,
            default: () => ({})
        },
        disableTypeSelect: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            mediaOptions: [
                {
                    value: null,
                    text: 'All types'
                },
                {
                    value: 'image',
                    text: 'Image'
                },
                {
                    value: 'video',
                    text: 'Video'
                }
            ],
            searchParams: {
                name: null,
                type: null,
                date: null
            }
        };
    },

    computed: {
        months() {
            const months = [];
            const date = moment().startOf('month');

            months.push({
                value: null,
                text: 'All dates'
            });

            months.push({
                value: date.format('YYYY-MM'),
                text: date.format('MMMM YYYY')
            });

            for (let i = 1; i <= 24; i++) {
                const earlierMonth = date.subtract(1, 'month');

                months.push({
                    value: earlierMonth.format('YYYY-MM'),
                    text: earlierMonth.format('MMMM YYYY')
                });
            }

            return months;
        }
    },

    watch: {
        'searchParams.type': function() {
            this.emit();
        },

        'searchParams.date': function() {
            this.emit();
        },

        'searchParams.name': function() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                this.emit();
            }, 500);
        }
    },

    created() {
        this.searchParams = { ...this.params };
    },

    methods: {
        emit() {
            this.$emit('searchChange', this.searchParams);
        }
    }
};
</script>
